import 'scss/pages/about-us.scss';
import React from 'react';
import { Trans } from '@lingui/macro';
import Layout from 'components/Layout';
import Seo from 'components/SEO';
import Image from 'components/atoms/Image/Image';
import {Link} from 'gatsby';
import {List, ListItem} from 'components/atoms/List/List';
import {careerContactFormProps, Perks} from "../../page-components/careers/common";

const UxDesigner = () => {
  return (
      <Layout contactFormProps={careerContactFormProps} >
        <Seo title="UX Designer at YanchWare"/>
        <section className="hero-wrapper">
          <div className="hero">
            <div className="hero__content">
              <h1 className="hero__title">
                <Trans>UX Designer @ YanchWare</Trans>
              </h1>
              <p className="hero__text">
                <Trans>
                  As a UX Designer at YanchWare, you will play a crucial role in shaping our brand identity and user
                  experience for our flagship software products, Fractal and Ocelot.
                </Trans>
              </p>
              <p className="hero__text">
                <Trans>
                  This part-time role involves collaborating closely with our marketing manager to develop a visually
                  compelling and coherent brand identity, aligning heavy technological concepts with accessible visuals.
                </Trans>
              </p>
              <Link to="/contact-us" className="bg-green-900 w-[190px] mt-m py-xs px-m rounded-[10px] flex justify-between">
                <span className="text-transparent bg-clip-text bg-hero-text">
                  <Trans>Apply now</Trans>
                </span>
                <Image name="arrow-icon" className="fill-icon mr-s" />
              </Link>
            </div>
            <Image name="careers/ux-designer" className="hero__image"/>
          </div>
        </section>

        <section className="container tac">
          <h2 className="color-primary">
            <Trans>About the role</Trans>
          </h2>

          <div className="content-blocks content-blocks--reverse">
            <div className="content-block">
              <Image
                  name="about-us-page/customer-success"
                  className="content-block__image"
              />
              <div className="content-block__info">
                <h2>
                  <Trans>Responsibilities:</Trans>
                </h2>
                <p>
                  <Trans>
                    In this creative and impactful role, you will:
                  </Trans>
                </p>
                <p>
                  <List>
                    <ListItem>
                      <Trans>
                        Translate complex technological concepts into clear, engaging visuals that resonate with
                        diverse audiences.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Define and develop the brand identity for YanchWare, including creating infographics, icons,
                        logos, visual materials, and small animations.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Work collaboratively with the marketing team to ensure that the visual identity aligns with our
                        corporate goals and user expectations.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Stay abreast of the latest trends in graphic design, UX/UI, and web design to continuously
                        improve and refine our visual and brand strategy.
                      </Trans>
                    </ListItem>
                  </List>
                </p>
              </div>
            </div>
            <div className="content-block">
              <Image
                  name="about-us-page/about-us-hero"
                  className="content-block__image"
              />
              <div className="content-block__info">

                <h2>
                  <Trans>Success Criteria:</Trans>
                </h2>
                <p>
                  <Trans>
                    Your effectiveness in this role will be measured by your ability to deliver on the following key
                    objectives, enhancing both our brand and user experience:
                  </Trans>
                </p>
                <p>
                  <List>
                    <ListItem>
                      <Trans>
                        Brand Cohesion: Successfully establish and maintain a unified visual identity across all
                        platforms and materials, ensuring consistency and alignment with our brand values.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        User Engagement: Create designs that not only look great but also function seamlessly,
                        significantly improving user interaction and satisfaction with our digital platforms.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Innovative Solutions: Continually introduce fresh, relevant visual concepts and designs that
                        push the boundaries of traditional B2B tech aesthetics.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Project Delivery: Efficiently manage and complete design projects within set deadlines,
                        maintaining high quality and innovation in every deliverable.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Team Collaboration: Actively contribute to and enhance team dynamics, ensuring open
                        communication and collaboration that fosters a positive and productive work environment.
                      </Trans>
                    </ListItem>
                  </List>
                </p>
              </div>
            </div>
            <div className="content-block">
              <Image
                  name="careers/whoareu"
                  className="content-block__image"
              />
              <div className="content-block__info">
                <h2>
                  <Trans>Who are you:</Trans>
                </h2>
                <p>
                  <Trans>
                    You are a dynamic and creative UX Designer with a keen eye for visual detail and a deep
                    understanding of brand aesthetics:
                  </Trans>
                </p>
                <p>
                  <List>
                    <ListItem>
                      <Trans>
                        Technically Proficient: Skilled in Adobe Suite, especially InDesign, Illustrator, Photoshop,
                        and Lightroom. Familiarity with Figma or similar tools is a plus.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Experienced in Web Design: You have a solid background in creating visually appealing and
                        user-friendly web designs, with a strong grasp of UX and UI principles.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        User-Centric: Your design philosophy is firmly rooted in creating an intuitive user experience
                        that prioritizes ease of use and engagement.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Detail-Oriented: You have a meticulous approach to design, from the overall layout to the
                        smallest details of typography and color theory.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Adaptable and Collaborative: An easy-going, friendly professional who thrives in team settings
                        and is keen to learn and tackle new challenges.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Language Skills: You are proficient in English, enabling effective communication in a
                        diverse workplace.
                      </Trans>
                    </ListItem>
                  </List>
                </p>
              </div>
            </div>
          </div>
          <Perks/>
        </section>
      </Layout>
  );
};

export default UxDesigner;
