import {ContactFormProps} from "components/ContactForm/ContactForm";
import Image from "components/atoms/Image/Image";
import {List, ListItem} from "components/atoms/List/List";
import React from "react";
import {Trans} from "@lingui/macro";

export const careerContactFormProps: ContactFormProps = {
    title: "Take the Next Step in Your Career with YanchWare",
    text: "We'd love to see what you can bring to our team. Apply now to join YanchWare and start your journey in a role where your skills, passion, and innovations drive real change.",
    actionText: "Contact us"
}

export const Perks = () => (<div className="content-block">
        <Image
            name="about-us-page/legacy"
            className="content-block__image"
        />
        <div className="content-block__info">
            <h2>
                <Trans>Perks:</Trans>
            </h2>
            <p>
                <Trans>
                    At YanchWare, we understand the importance of a balanced work-life integration and personal
                    well-being. That’s why we offer a range of benefits designed to support you both professionally
                    and personally:
                </Trans>
            </p>
            <p>
                <List>
                    <ListItem>
                        <Trans>
                            Four-Day Work Week: To further support our team's well-being and productivity,
                            we operate on a four-day work week, giving you a three-day weekend to relax, pursue hobbies,
                            or
                            spend time with loved ones.
                        </Trans>
                    </ListItem>
                    <ListItem>
                        <Trans>
                            Flexible Working Arrangements: Enjoy the freedom of flexible working hours and
                            the option to work 100% remotely. This not only helps in maintaining a better work-life
                            balance
                            but also reduces your carbon footprint, supporting a healthier environment.
                        </Trans>
                    </ListItem>
                    <ListItem>
                        <Trans>
                            Comprehensive Home Office Setup: Kickstart your remote work with a €1,500
                            allowance to create your ideal home office setup, ensuring you have a comfortable and
                            productive workspace.
                        </Trans>
                    </ListItem>
                    <ListItem>
                        <Trans>
                            Monthly Allowance for Personal Development: Each month, benefit from a €150
                            allowance to spend on technology upgrades, training courses, or wellness programs, helping
                            you stay at the forefront of your field while also taking care of your health.
                        </Trans>
                    </ListItem>
                    <ListItem>
                        <Trans>
                            International Work Environment: Be part of a diverse team that brings together
                            talented
                            professionals from around the globe, offering rich cultural exchanges and a broader
                            perspective
                            on both work and life.
                        </Trans>
                    </ListItem>
                    <ListItem>
                        <Trans>
                            Career Growth Opportunities: As a rapidly growing scale-up with solid
                            investment backing, YanchWare offers unparalleled opportunities for career advancement. Make
                            a
                            significant impact as you grow with us, shaping not just your career but the future of cloud
                            computing.
                        </Trans>
                    </ListItem>
                </List>
            </p>
        </div>
    </div>
);