import './List.scss';
import React, {FC} from 'react';

type Props = {
    children?: React.ReactNode
};

export const List: FC<Props> = ({children}) => {
    if (!children) {
        return null;
    }

    return (<ul className="list">{children}</ul>);
}

export const ListItem: FC<Props> = ({children}) => {
    if (!children) {
        return null;
    }

    return (<li className="list__item">{children}</li>);
}


